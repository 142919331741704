import { Badge, Carousel, Col, Container, Image, Row } from "react-bootstrap";

import "./style.css";

import logowhite from "./images/logowhite.svg";
import test from "./images/test.png";
import kurosaki from "./images/kurosaki.png";
import tokushima from "./images/tokushima.png";
import ooshio from "./images/ooshio.png";
import map from "./images/map.svg";
import blog1 from "./images/blog1.png";
import blog2 from "./images/blog2.png";

import youtube from "./images/youtubeicon.svg";
import insta from "./images/instagramicon.svg";
import herovideo from "./images/opening.mp4";

function App() {
  return (
    <>
      <main className="showcase">
        {/* ナビ */}
        <header className="">
          <div className="c-header c-hamburger-menu">
            <a href="#" className="c-header__logo">
              <img
                src={logowhite}
                // width={168}
                height={30}
                alt="logo"
                className="logo_img"
              />
            </a>

            <input
              type="checkbox"
              name="hamburger"
              id="hamburger"
              className="c-hamburger-menu__input"
            />
            <label for="hamburger" className="c-hamburger-menu__bg"></label>

            <ul className="c-header__list c-hamburger-menu__list">
              <li className="c-header__list-item">
                <a href="#shiru" className="nav_text c-header__list-link">
                  しる
                </a>
              </li>
              <li className="c-header__list-item">
                <a href="#miru" className="nav_text c-header__list-link">
                  みる
                </a>
              </li>
              <li className="c-header__list-item">
                <a href="#yomu" className="nav_text c-header__list-link">
                  よむ
                </a>
              </li>
              <li className="c-header__list-item">
                <a href="#tsugi" className="nav_text c-header__list-link">
                  つぎへ
                </a>
              </li>
            </ul>

            <label for="hamburger" className="c-hamburger-menu__button">
              <span className="c-hamburger-menu__button-mark"></span>
              <span className="c-hamburger-menu__button-mark"></span>
              <span className="c-hamburger-menu__button-mark"></span>
            </label>
          </div>
        </header>

        <video src={herovideo} muted autoPlay loop playsInline></video>

        <div className="overlay"></div>

        {/* <div className="border_btn01"> */}

        <a href="#" class="border_btn01 hero_btn">
          VIEW ALL
        </a>
        {/* </div> */}
      </main>
      <section className="">
        <div className="container">
          <h2 className="maintitle_text">
            「旅」をし「ながら」、
            <br />
            地域―ローカル―との出会い。
          </h2>
        </div>
      </section>

      {/* しる */}
      <section id="shiru" className="section_red">
        <div className="container">
          <Row>
            <Col xs={12} md={6}>
              <div className="title_box">
                <span className="title_backtext">知</span>
                <h1 className="title_text">しる</h1>
                <h2 className="title_subtext">地域 と 文化。</h2>
                <p></p>
              </div>

              <Image src={map} className="image_map" />
            </Col>
            <Col xs={12} md={6}>
              <Carousel className="image_box">
                <Carousel.Item interval={1000}>
                  <Image
                    src={kurosaki}
                    text="First slide"
                    className="image_item"
                  />
                  {/* <Carousel.Caption>
                    <h3></h3>
                    <p></p>
                  </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item interval={500}>
                  <Image
                    src={tokushima}
                    text="Second slide"
                    className="image_item"
                  />
                  <Carousel.Caption>
                    {/* <h3></h3>
                    <p></p> */}
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <Image
                    src={ooshio}
                    ExampleCarouselImage
                    text="Third slide"
                    className="image_item"
                  />
                  <Carousel.Caption>
                    {/* <h3></h3>
                    <p></p> */}
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </div>
      </section>

      {/* みる */}
      <section id="miru" className="section_white">
        <div className="container">
          <Row>
            <Col xs={12} md={6}>
              <div className="title_box">
                <span className="title_backtext title_backtext_red">見</span>
                <h1 className="title_text">みる</h1>
                <h2 className="title_subtext">動画 か 現地。</h2>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <iframe
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/V8X-5pYEWkg?si=DgzyMzBfyFfSlVeU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </Col>
          </Row>
          <Row className="video_box">
            <Col xs={12} md={4}>
              <iframe
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/V8X-5pYEWkg?si=DgzyMzBfyFfSlVeU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </Col>
            <Col xs={12} md={4}>
              <iframe
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/V8X-5pYEWkg?si=DgzyMzBfyFfSlVeU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </Col>
            <Col xs={12} md={4}>
              <iframe
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/V8X-5pYEWkg?si=DgzyMzBfyFfSlVeU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </Col>
          </Row>
        </div>
      </section>

      {/* よむ */}
      <section id="yomu" className="section_bule">
        <div className="container">
          <Row>
            <Col xs={12} md={3}>
              <span className="title_backtext">読</span>
              <h1 className="title_text">よむ</h1>
            </Col>
            <Col xs={12} md={9}>
              <h2 className="title_subtext">地域 と 人と、 文化 と 人と。</h2>
            </Col>
          </Row>
          {/* 記事1 */}
          <Row className="blog_box">
            <Col xs={12} md={7}>
              <Image src={blog1} className="image_item" />
            </Col>
            <Col xs={12} md={5}>
              <Row className="blog_datebox">
                <Col>
                  <h4 className="blog_date">0000/00/00</h4>
                </Col>
                <Col>
                  <Badge pill bg="primary">
                    祭人
                  </Badge>
                </Col>
              </Row>
              <h3 className="blog_title">祭りに関わる人のオンとオフ。</h3>
              <a href="#" class="border_btn01">
                VIEW ALL
              </a>
            </Col>
          </Row>
          {/* 記事2 */}
          <Row className="blog_box blog_boxsm">
            <Col xs={12} md={5}>
              <Row className="blog_datebox">
                <Col>
                  <h4 className="blog_date">0000/00/00</h4>
                </Col>
                <Col>
                  <Badge pill bg="primary">
                    祭人
                  </Badge>
                </Col>
              </Row>
              <h3 className="blog_title">祭りに関わる人のオンとオフ。</h3>
              <a href="#" class="border_btn01">
                VIEW ALL
              </a>
            </Col>
            <Col xs={12} md={7}>
              <Image src={blog2} className="image_item" />
            </Col>
          </Row>
        </div>
      </section>

      {/* つぎへ */}
      <section id="tsugi" className="section_white">
        <div className="container">
          <div className="title_box">
            <span className="title_backtext title_backtext_red">次</span>
            <h1 className="title_text_3">つぎへ</h1>
            <Row>
              <Col xs={12} md={6}>
                {" "}
                <h2 className="title_subtext">
                  2025も引き続き旅を続けていきます。
                </h2>
              </Col>
              <Col xs={12} md={6}>
                <a href="https://www.youtube.com/@nagaratabi" target="_blank">
                  <Image
                    src={youtube}
                    width={100}
                    height={100}
                    className="img_sns"
                  />
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  <Image
                    src={insta}
                    width={100}
                    height={100}
                    className="img_sns"
                  />
                </a>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <Image src={logowhite} width={200} />
          <p className="catch">旅をしながら、地域を発信するWEBメディア</p>
        </div>
      </footer>
    </>
  );
}

export default App;
